import React, { useEffect } from 'react'

import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Signin.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { signUpwithLinkedIn } from '../../redux/actions/user/user.actions'
import { linkedInClientId, routePaths } from '../../shared/config/globalConfig'

const Signin = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const code = params.get('code'); // Extract authorization code
      const state = params.get('state'); // Extract state parameter
  
      if (code !== null) {
        let data = {
          code: code,
          redirect_url: state
            ? `${process.env.REACT_APP_REDIRECT_URL}?state=${encodeURIComponent(state)}`
            : process.env.REACT_APP_REDIRECT_URL, // Append state if it exists
        };
  
        dispatch(signUpwithLinkedIn(data)).then((res) => {
          if (res.data) {
            history.push({
              pathname: `${routePaths.proApp}`,
            });
          }
        });
      }
    }
  }, [dispatch, history, location]);
  

  return (
    // <div className="sign-in">
    //   <Button
    //     variant="outline-warning"
    //     onClick={() => alert('Implementation is in progress, Kindly wait!')}
    //   >

    <a
      // href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=r_liteprofile%20r_emailaddress&client_id=788mb5vl2mgwuq&redirect_uri=http%3A%2F%2Flocalhost%3A3008%2Flinkedin-auth"
      href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=r_liteprofile%20r_emailaddress&client_id=${linkedInClientId}&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_REDIRECT_URL
        // 'http://localhost:3000/pro'
        // 'https://test.gimtransfer.com/pro'
        // 'https://gimtransfer.com/pro'
      )}`}
      className="login-btn btn"
    >
      <FontAwesomeIcon icon={faLinkedin} /> {t('Header.signIn')}
    </a>

    //    </Button>{' '}
    // </div>
  )
}
export default Signin
